import {Injectable} from '@angular/core';
import {NxDatepickerIntl} from '@aposin/ng-aquila/datefield';

@Injectable({providedIn: 'root'})
export class DatepickerIntlService extends NxDatepickerIntl {

  calendarLabel = 'Kalender';
  openCalendarLabel = 'Kalender Öffnen';
  prevMonthLabel = 'Vorheriger Monat';
  nextMonthLabel = 'Nächster Monat';
  prevYearLabel = 'Vorheriges Jahr';
  nextYearLabel = 'Nächstes Jahr';
  prevMultiYearLabel = 'Vorherige 20 Jahre';
  nextMultiYearLabel = 'Nächste 20 Jahre';
  switchToMonthViewLabel = 'Wähle Datum';
  switchToMultiYearViewLabel = 'Wähle Jahr & Monat';
}


