import {Injectable} from '@angular/core';
import {RedirectService} from './redirect.service';
import {AuthService} from './auth/auth.service';
import {ConfigurationService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceHubRedirectService {

  constructor(private readonly authService: AuthService,
              private readonly redirectService: RedirectService,
              private readonly configService: ConfigurationService) {
  }

  isServiceHubRedirectAvailable(): boolean {
    return this.authService.isAgent();
  }

  doRedirect(): void {
    this.configService.getConfig().subscribe(config => this.redirectService.redirect(`${config.serviceHubUrl}`));
  }

}
