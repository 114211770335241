import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ServiceHubRedirectService} from '../../../core/services/service-hub-redirect.service';
import {AppStateService} from '../../../core/services/app-state.service';
import {WeweResponse} from '../../../core/models/wewe.response';

@Component({
  selector: 'dsmkd-wewe-success',
  templateUrl: './wewe-success.component.html',
  styleUrls: ['./wewe-success.component.scss']
})
export class WeweSuccessComponent implements OnInit {

  // The id is added as suffix to the message identifier, see the html
  id = 'default';

  weweResponse: WeweResponse;

  constructor(private readonly route: ActivatedRoute,
              private readonly serviceHubRedirectService: ServiceHubRedirectService,
              private readonly appStateService: AppStateService) {
  }

  ngOnInit() {
    // https://angular.io/guide/router#snapshot-the-no-observable-alternative
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id === null) {
      this.id = 'default';
    }

    this.weweResponse = this.appStateService.weweResponse;
  }

  navigateToServiceHub() {
    this.serviceHubRedirectService.doRedirect();
  }

}

