import {Component, OnInit} from '@angular/core';
import {AppStateService} from '../../../core/services/app-state.service';
import {KommDataPostResponse} from '../../../core/models/komm-data-post.response';
import {UrlApiService} from '../../../core/services/url-api.service';
import {ServiceHubRedirectService} from '../../../core/services/service-hub-redirect.service';
import {ProgramParticipationType} from '../../../core/models/kommdata.model';
import {AuthService} from '../../../core/services/auth/auth.service';
import {ConsentAddResponse} from '../../../core/models/consent-add-response';
import {KommdataAddResponse} from '../../../core/models/kommdata-add-response';
import {ConsentType} from '../../../core/models/consent-element';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'dsmkd-kommdata-add-success',
  templateUrl: './komm-data-add-success.component.html',
  styleUrls: ['./komm-data-add-success.component.scss']
})
export class KommDataAddSuccessComponent implements OnInit {

  kommDataAddResponse: KommDataPostResponse;
  programParticipationTypes: ProgramParticipationType[];
  inboundRoute: string;
  isCustomerFromSDW: boolean;
  consentAddResponse: ConsentAddResponse;
  kommdataAddResponse: KommdataAddResponse;
  dEWEFeatureFlag: boolean;
  cameFromEdit: boolean = false;

  constructor(private readonly appStateService: AppStateService,
              private readonly urlApiService: UrlApiService,
              private readonly serviceHubRedirectService: ServiceHubRedirectService,
              private readonly authService: AuthService,
              private readonly route: ActivatedRoute,
              private readonly router: Router
  ) {

  }

  ngOnInit(): void {
    this.inboundRoute = this.urlApiService.getParams()?.inboundRoute;
    this.isCustomerFromSDW = this.authService.isNovaSDW();
    this.dEWEFeatureFlag = this.appStateService.configResponse.deweProcessingFeatureFlag;
    if (this.dEWEFeatureFlag) {
      this.consentAddResponse = this.appStateService.consentAddResponse;
      this.kommdataAddResponse = this.appStateService.kommdataAddResponse;
    } else {
      this.kommDataAddResponse = this.appStateService.kommDataPostResponse;
      this.programParticipationTypes = this.appStateService.programParticipationTypes;
    }
    this.route.queryParams.subscribe(params => {
      this.cameFromEdit = params['from'] === 'edit';
    });
  }

  showRegErrorMessage(): boolean {
    // if registrationResponse is undefined or regCallSuccessful is null we don't show the error message
    let showErrorMessage: boolean;
    if (this.dEWEFeatureFlag) {
      showErrorMessage = this.consentAddResponse.consentsAddingFailed.length > 0;
    } else {
      showErrorMessage = this.kommDataAddResponse.registrationResponse?.registrationCallSuccessful === false;
    }
    return showErrorMessage;
  }

  navigateToServiceHub() {
    this.serviceHubRedirectService.doRedirect();
  }

  addedKommdataSuccessful(): boolean {
    let kommdataWereAdded = this.kommDataAddResponse?.kommdataAdded;
    if (this.dEWEFeatureFlag) {
      kommdataWereAdded = this.kommdataAddResponse.kommdataAddSuccessful;
    }
    return kommdataWereAdded;
  }

  mazConsentSuccessful(): boolean {
    let mazConsentAdded = this.programParticipationTypes?.length > 0;
    if (this.dEWEFeatureFlag) {
      mazConsentAdded = this.consentAddResponse?.consentsAddingSuccessful.find(consent => consent.consentType === ConsentType.MAZ) !== undefined;
    }
    return mazConsentAdded;
  }

  navigateToEdit() {
    this.router.navigate(['/edit']);
  }

}


