import {OmModel} from '../services/auth/model/auth.model';

export enum AuthState {
  NONE, MAZ, ANON, AGENT
}

export class AuthInfoModel {
  authState: AuthState;
  fk?: string;
  aa?: string;
  ng?: string;
  omModel?: OmModel;
  vtnr?: string;
}
