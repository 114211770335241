import {HttpErrorResponse} from '@angular/common/http';

export class HttpErrorResponseUtils {

  /**
   * Extracts the error codes from the HttpErrorResponse.
   * @returns A set with error codes. Never null or undefined.
   */
  static getErrorCodes(httpErrorResponse: HttpErrorResponse): Set<string> {
    const codes = new Set<string>();
    if (httpErrorResponse && httpErrorResponse.error &&
      typeof httpErrorResponse.error === 'object' && 'errors' in httpErrorResponse.error) {

      // payload.error should be a BackendResponse, but here we try not to make to much assumptions
      // Note: errorcodes and errorids should be shown only once.
      httpErrorResponse.error.errors.filter(e => 'errorId' in e).forEach(e => codes.add(e['errorId']));
      // in BackendResponse code is a number.
      httpErrorResponse.error.errors.filter(e => 'code' in e).forEach(e => codes.add(`${e['code']}`));
    }
    return codes;
  }
}
