import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {KommDataChangeMazComponent} from './components/komm-data-change-maz/komm-data-change-maz.component';
import {KommDataChangeSummaryComponent} from './components/komm-data-change-summary/komm-data-change-summary.component';
import {KommDataChangeSuccessComponent} from './components/komm-data-change-success/komm-data-change-success.component';
import {KommChangeSuccessPageDeactivationGuard} from './service/komm-change-success-page-deactivation.guard';

const routes: Routes = [
  {path: '', component: KommDataChangeMazComponent},
  {path: 'summary', component: KommDataChangeSummaryComponent},
  {path: 'success', component: KommDataChangeSuccessComponent, canDeactivate: [KommChangeSuccessPageDeactivationGuard]},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KommDataChangeRoutingModule {
}
