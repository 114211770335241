import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'combinedProgramType'
})
export class CombinedProgramTypePipe implements PipeTransform {

  transform(programParticipations: string[], ...args: unknown[]): string {
    switch (programParticipations.length) {
    case 0:
      return '';
    case 1:
      return programParticipations[0];
    case 2:
      return `${programParticipations[0]} und ${programParticipations[1]}`;
    case 3:
      return `${programParticipations[0]}, ${programParticipations[1]} und ${programParticipations[2]}`;
    default:
      return '';
    }
  }

}
