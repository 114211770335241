import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {EMPTY, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'dsmkd-error-message-container',
  templateUrl: './error-message-container.component.html',
  styleUrls: ['./error-message-container.component.scss']
})
export class ErrorMessageContainerComponent {

  @Input() control: AbstractControl;

  @Input() messagePrefix: string;

  @Input() class: string;

  @Input() translateParamters: any;

  constructor(private readonly translateService: TranslateService) {
  }

  getErrors(): Observable<IdAndMessage[]> {
    if (this.control.untouched || this.control.valid || !this.control.errors) {
      return EMPTY;
    }

    const messageKeys: string[] = Object.keys(this.control.errors).map(k => `${this.messagePrefix}.${k}`);

    return this.translateService.get(messageKeys, this.translateParamters).pipe(map(
      translations => {

        const unfilterdResult = Object.keys(translations).map(key => {
          return {
            // ids with dot ar difficult to select with css selectors. therefore replace dot with underscore.
            id: key.replace(/\./g, '_'),
            message: translations[key]
          } as IdAndMessage;
        });

        return unfilterdResult.reduce((accumulated: IdAndMessage[], element: IdAndMessage) => {
          if (!accumulated.find(el => el.message === element.message)) {
            accumulated.push(element);
          }
          return accumulated;
        }, []);
      }
    ));
  }
}

interface IdAndMessage {
  id: string;
  message: string;
}

