import {KommDataItem} from '../../core/models/kommdata.model';

export interface KommDataChangeSummaryModel {
  kommdataChangeEmails: KommDataChangeSummaryItem[];
  kommdataChangeMobileNumbers: KommDataChangeSummaryItem[];
}

export interface KommDataChangeSummaryItem {
  item: KommDataItem;
  type: KommDataChangeSummaryType;
}

export enum KommDataChangeSummaryType {
  NEW, OLD
}
