import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WeweFormComponent} from './components/wewe-form/wewe-form.component';
import {WeweRoutingModule} from './wewe-routing.module';
import {StylesModule} from '../styles/styles.module';
import {ReactiveFormsModule} from '@angular/forms';
import {WewePersonDetailComponent} from './components/wewe-person-detail/wewe-person-detail.component';
import {FilesUploadModule} from '../files-upload/files-upload.module';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {WeweSuccessComponent} from './components/wewe-success/wewe-success.component';
import {ErrorComponent} from '../shared/components/error/error.component';
import {FeedbackModule} from '../shared/feedback/feedback.module';

@NgModule({
  declarations: [
    WeweFormComponent,
    WewePersonDetailComponent,
    WeweSuccessComponent,
    ErrorComponent
  ],
  imports: [
    CommonModule,
    WeweRoutingModule,
    StylesModule,
    ReactiveFormsModule,
    FilesUploadModule,
    SharedModule,
    TranslateModule,
    HttpClientModule,
    FeedbackModule,
  ],
  providers: [],
})

export class WeweModule {
}
