import {Component, OnInit} from '@angular/core';
import {LoadingAnimationService} from '../../../core/services/loading-animation.service';

@Component({
  selector: 'dsmkd-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent implements OnInit {

  showLoadingComponent = false;

  constructor(private readonly loadingAnimationService: LoadingAnimationService) {
  }

  ngOnInit() {
    this.loadingAnimationService.showLoading.subscribe(val => {
      this.showLoadingComponent = val;
    });
  }

}
