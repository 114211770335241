import {Injectable} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class OverlayService {

  private readonly httpErrorSubject: Subject<HttpErrorResponse> = new ReplaySubject();

  getHttpErrorSubject(): Subject<HttpErrorResponse> {
    return this.httpErrorSubject;
  }

  showErrorOverlay(httpErrorResponse?: HttpErrorResponse): void {
    this.httpErrorSubject.next(httpErrorResponse);
  }
}
