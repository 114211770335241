import {Injectable} from '@angular/core';

export interface UrlApiParams {
  readonly inboundRoute: string;
  readonly trackingSourceDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class UrlApiService {

  private _read = false;
  private _params: UrlApiParams;

  constructor(/* This service will be used as APP_INITIALIZER, take care of dependencies! */) {
  }

  public readAndClearOnce(): Promise<boolean> {
    if (this._read) {
      return;
    }

    this._read = true;

    const url = new URL(window.location.href);

    this._params = {
      inboundRoute: this.readParam(url, 'inboundRoute', 'DSH'),
      trackingSourceDetail: this.readParam(url, 'trackingSourceDetail', '')
    };

    if (!!this._params.inboundRoute) {
      // only remove params from url if we initialy jump to bankdaten
      // we has other calls like the return after esign which need to keep the params
      const urlStripped = url.origin + url.pathname;
      if (urlStripped !== window.location.href) {
        window.history.replaceState({}, window.name, urlStripped);
      }
    }

    return Promise.resolve(true);
  }

  getParams(): UrlApiParams {
    return this._params;
  }

  private readParam(url: URL, key: string, fallbackParam: string): string {
    const val = url.searchParams.get(key);
    if (!val) {
      return fallbackParam;
    }

    return decodeURIComponent(val);
  }
}
