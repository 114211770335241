import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'phoneNumberFormat'
})
export class PhoneNumberFormatPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (value) {
      let result = value;
      result = this.replaceCountryCodePrefix(result);
      result = this.replaceSlashes(result);
      result = this.replaceLeadingZero(result);
      if (result.indexOf(' ') === -1) {
        result = this.addMissingSpaces(result);
      }
      return result;
    }

    return value;
  }

  private replaceCountryCodePrefix(value: string): string {
    return value.replace(/^00/, '+');
  }

  private replaceSlashes(value: string): string {
    return value.replace(/\//g, ' ');
  }

  private addMissingSpaces(value: string): string {
    const lastPosition = 7;
    value = [value.slice(0, lastPosition), ' ', value.slice(lastPosition)].join('');
    const firstPosition = 3;
    return [value.slice(0, firstPosition), ' ', value.slice(firstPosition)].join('');
  }

  private replaceLeadingZero(value: string): string {
    return value.replace(/(\+\d+ )0(\d+ )+?/, '$1$2');
  }
}
