import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StylesModule} from '../../styles/styles.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FeedbackForServiceComponent} from './components/feedback-for-service/feedback-for-service.component';
import {FeedbackDoneComponent} from './components/feedback-done/feedback-done.component';
import {TranslateModule} from '@ngx-translate/core';
import {NxRadioModule} from '@aposin/ng-aquila/radio-button';
import {SharedModule} from '../shared.module';
import {NX_MODAL_DEFAULT_OPTIONS, NxModalModule} from '@aposin/ng-aquila/modal';

const feedbackComponents = [
  FeedbackForServiceComponent,
  FeedbackDoneComponent
];

@NgModule({
  imports: [
    CommonModule,
    StylesModule,
    ReactiveFormsModule,
    TranslateModule,
    NxModalModule,
    NxRadioModule,
    SharedModule
  ],
  declarations: feedbackComponents,
  exports: feedbackComponents
})
export class FeedbackModule {
}
