import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KommDataDigitalProfileComponent} from './components/komm-data-digital-profile/komm-data-digital-profile.component';
import {KommDataAddRoutingModule} from './komm-data-add-routing.module';
import {StylesModule} from '../styles/styles.module';
import {KommDataAddSuccessComponent} from './components/komm-data-add-success/komm-data-add-success.component';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FORMFIELD_DEFAULT_OPTIONS, FormfieldDefaultOptions, NxFormfieldModule} from '@aposin/ng-aquila/formfield';
import {ContactDataTypePipe} from '../core/pipes/contact-data-type.pipe';
import {NxAccordionModule} from '@aposin/ng-aquila/accordion';
import {FeedbackModule} from '../shared/feedback/feedback.module';
import {KommDataMazEsbSuccessComponent} from './components/komm-data-maz-esb-success/komm-data-maz-esb-success.component';
import {KommDataSuccessComponent} from './components/komm-data-success/komm-data-success.component';
import {ScrollingService} from '../core/services/scrolling.service';
import {KommDataProgramParticipationsComponent} from './components/komm-data-program-participations/komm-data-program-participations.component';
import {KommDataResilienceComponent} from './components/komm-data-resilience/komm-data-resilience.component';
import {IdentifiedCheckComponent} from './components/komm-data-digital-profile/identified-check/identified-check.component';
import {NxDropdownModule} from '@aposin/ng-aquila/dropdown';
import {KommDataProgramOverviewComponent} from './components/komm-data-programm-overview/komm-data-program-overview.component';
import {NxPhoneInputModule} from '@aposin/ng-aquila/phone-input';

const formfieldExpertOptions: FormfieldDefaultOptions = {
  appearance: 'outline',
  nxFloatLabel: 'always'
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    KommDataDigitalProfileComponent,
    KommDataAddSuccessComponent,
    KommDataMazEsbSuccessComponent,
    KommDataSuccessComponent,
    KommDataProgramParticipationsComponent,
    KommDataResilienceComponent,
    IdentifiedCheckComponent,
    KommDataProgramOverviewComponent
  ],
  imports: [
    CommonModule,
    KommDataAddRoutingModule,
    StylesModule,
    SharedModule,
    TranslateModule,
    NxAccordionModule,
    FeedbackModule,
    NxFormfieldModule,
    NxDropdownModule,
    NxPhoneInputModule
  ],
  exports: [
    KommDataProgramOverviewComponent
  ],
  providers: [
    ContactDataTypePipe,
    ScrollingService,
    {provide: FORMFIELD_DEFAULT_OPTIONS, useValue: formfieldExpertOptions},
  ]
})
export class KommDataAddModule {
}
