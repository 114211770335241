import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {KommDataChangeSuccessComponent} from '../components/komm-data-change-success/komm-data-change-success.component';

@Injectable({
  providedIn: 'root'
})
export class KommChangeSuccessPageDeactivationGuard implements CanDeactivate<KommDataChangeSuccessComponent> {

  canDeactivate(component: KommDataChangeSuccessComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree { // NOSONAR

    return false;
  }

}
