import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigModel} from '../models/config-model';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private readonly CONFIG_URL = 'config/frontend';

  constructor(private readonly httpClient: HttpClient) {
  }

  public getConfig(): Observable<ConfigModel> {
    return this.httpClient.get<ConfigModel>(this.CONFIG_URL);
  }
}
