import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ProcessIdService} from '../core/services/process-id.service';
import {SessionIdService} from '../core/services/session-id.service';

@Injectable()
export class CommonHeadersInterceptor implements HttpInterceptor {

  constructor(private readonly processIdService: ProcessIdService,
              private readonly sessionIdService: SessionIdService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let dshSessionId = this.sessionIdService.getDSHSessionId();
    if (!dshSessionId) {
      dshSessionId = '';
    }

    const processID = this.processIdService.get();
    const reqWithHeaders = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'X-Process-Id': processID,
        'Process-Uuid': processID,
        'X-DSH-Session-Id': dshSessionId,
      }
    });

    return next.handle(reqWithHeaders);
  }
}
