import { Injectable } from '@angular/core';
import { v4 } from 'uuid';

@Injectable()
export class ProcessIdService {

  private currentProcessId: string;

  constructor() {
  }

  public get(): string {
    if (!this.currentProcessId) {
      this.currentProcessId = v4();
    }

    return this.currentProcessId;
  }

  public set(processId: string): void {
    this.currentProcessId = processId;
  }

}
