<header nx-header>
  <nx-header-row>
    <nx-header-brand class="nx-margin-right-5xl">
      <nx-link>
        <a href="/" aria-label="Zur Startseite">
          <figure nxFigure>
            <img src="../../../../assets/logos/allianz_logo.svg"
                 alt="Zur Startseite" role="none">
          </figure>
        </a>
      </nx-link>
      <nx-header-app-title>{{ 'header.SH' | translate}}</nx-header-app-title>
    </nx-header-brand>
    <nx-header-actions>
      <dsmkd-header-user-details>
      </dsmkd-header-user-details>
    </nx-header-actions>
  </nx-header-row>
</header>
