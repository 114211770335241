import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BackendResponse} from '../models/backend-data-model';
import {ConsentAddRequest} from '../models/consent-add-request';
import {ConsentAddResponse} from '../models/consent-add-response';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {

  private readonly consentUrl = 'api/rest/dsm/';

  constructor(private readonly http: HttpClient) {
  }

  addConsent(consentAddRequest: ConsentAddRequest): Observable<BackendResponse<ConsentAddResponse>> {
    return this.http.post<BackendResponse<ConsentAddResponse>>(`${this.consentUrl}consent/add`, consentAddRequest);
  }
}
