import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {FormControl} from '@angular/forms';

/**
 * Basic textarea wrapper component.
 */
@Component({
  selector: 'dsmkd-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css']
})
export class TextareaComponent implements AfterViewInit {

  // from https://v8.material.angular.io/cdk/text-field/overview
  @ViewChild('autosize', {static: false}) autosize: CdkTextareaAutosize;

  @Input() textAreaFormControl: FormControl;

  @Input() placeholder: string;

  characterCount = 0;

  constructor() {
  }

  private labelString = 'Nachricht';

  get label(): string {
    return this.labelString;
  }

  @Input()
  set label(label: string) {
    this.labelString = label;
  }

  private maxCharacterCountNumber: number;

  get maxCharacterCount(): number {
    return this.maxCharacterCountNumber ?? 20000;
  }

  @Input()
  set maxCharacterCount(maxCharacterCount: number) {
    this.maxCharacterCountNumber = maxCharacterCount;
  }

  private minRowsNumber = 10;

  get minRows(): number {
    return this.minRowsNumber ?? 10;
  }

  @Input()
  set minRows(minRows: number) {
    this.minRowsNumber = minRows;
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.countCharacters(), 1);
  }

  onKeyUp() {
    this.countCharacters();
  }

  private countCharacters() {
    this.characterCount = this.textAreaFormControl?.value?.length ?? 0;
  }
}
