<div [hidden]="showErrorOverlay">
  <ng-content></ng-content>
</div>

<ng-container *ngIf="showErrorOverlay">
  <div nxRow nxRowJustify="center" class="nx-margin-top-4m">
    <div nxCol="12,12,8,8" class="nx-margin-top-3xl nx-margin-bottom-s u-text-align-center">
      <img src="./assets/images/error.png" alt="Verbindungsproblem" width="271px" height="150px">
    </div>
  </div>
  <div nxRow nxRowJustify="center" class="nx-margin-top-l">
    <div nxCol="6" class="card-header u-text-align-center">
      <h2 nxHeadline="section" class="u-text-align-center">Verbindungsproblem</h2>
      <p nxCopytext="large" class="u-text-align-center nx-margin-top-2m">
        Leider ist ein Problem

        mit der Anwendung aufgetreten.
        Bitte versuchen Sie es später noch einmal.
      </p>

      <nx-link class="nx-margin-top-s u-text-uppercase">
        <a (click)="showErrorOverlay = false">
          <span aria-hidden="true" class="nx-link__icon c-icon c-icon--arrow-right"></span>
          Erneut versuchen
        </a>
      </nx-link>

    </div>
  </div>

  <div *ngIf="errorCodes" nxRow nxRowJustify="center" class="nx-margin-top-2m">
    <div id="error-codes" nxCopytext="normal" class="padding-16">ErrorCode: <strong>{{errorCodes}}</strong></div>
  </div>
</ng-container>
