<nx-formfield [nxLabel]="label">
  <textarea nxInput type="text"
            [formControl]="textAreaFormControl"
            [maxlength]="maxCharacterCount"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            [cdkAutosizeMinRows]="minRows"
            [cdkAutosizeMaxRows]="10"
            (keyup)="onKeyUp()"
            [placeholder]="placeholder">
  </textarea>
  <nx-error nxFormfieldError>
    Bitte geben Sie eine Nachricht ein.
  </nx-error>
</nx-formfield>
<div style="text-align: right; margin-top: -20px;"
     [class.color]=" this.characterCount >= this.maxCharacterCount ? 'red': 'grey'">
  {{characterCount}}/{{maxCharacterCount}}
</div>
