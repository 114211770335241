import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'dsmkd-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  private readonly ERROR_PREFIX = 'errorcomponent.';

  // The id is added as suffix to the message identifier, see the html
  id: string;
  mainMessageKey: string;

  mainMessage: string;
  detailedMessage2ndLineKey: string;

  detailedMessage2ndLine: string;
  helpLinkKey: string;

  helpLink: string;

  constructor(private readonly route: ActivatedRoute, private readonly translateService: TranslateService) {
  }

  ngOnInit() {
    // https://angular.io/guide/router#snapshot-the-no-observable-alternative
    this.id = this.route.snapshot.paramMap.get('id');

    this.setTextKeys(this.id);

    this.translateService
      .get([this.mainMessageKey, this.detailedMessage2ndLineKey, this.helpLinkKey])
      .subscribe(translations => {
        this.mainMessage = translations[this.mainMessageKey];
        this.detailedMessage2ndLine = translations[this.detailedMessage2ndLineKey];
        this.helpLink = translations[this.helpLinkKey];
      }
      );
  }

  private setTextKeys(id: string): void {
    this.mainMessageKey = `${this.ERROR_PREFIX}${id}.message`;
    this.detailedMessage2ndLineKey = `${this.ERROR_PREFIX}${id}.detailMessage2ndLine`;
    this.helpLinkKey = `${this.ERROR_PREFIX}${id}.helpLink`;
  }

  hasTranslatedTextForKey(text: string, key: string) {
    if (!text || text.trim() === '') {
      return false;
    }
    return text !== key;
  }
}
