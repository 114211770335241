import {Component, OnInit} from '@angular/core';
import {ServiceHubRedirectService} from '../../../core/services/service-hub-redirect.service';
import {ScrollingService} from '../../../core/services/scrolling.service';
import {AppStateService} from '../../../core/services/app-state.service';
import {AuthService} from '../../../core/services/auth/auth.service';

@Component({
  selector: 'dsmkd-komm-data-change-success',
  templateUrl: './komm-data-change-success.component.html',
  styleUrls: ['./komm-data-change-success.component.scss']
})
export class KommDataChangeSuccessComponent implements OnInit {

  isEmailChangeSuccessful: boolean = true;
  isMobileChangeSuccessful: boolean = true;
  isCustomerFromSDW: boolean = false;

  constructor(private readonly serviceHubRedirectService: ServiceHubRedirectService,
              private readonly appStateService: AppStateService,
              private readonly authService: AuthService,
              private readonly scrollingService: ScrollingService) {
  }

  ngOnInit(): void {
    this.isEmailChangeSuccessful = this.appStateService.kommDataChangeResponse.emailChangeSuccess;
    this.isMobileChangeSuccessful = this.appStateService.kommDataChangeResponse.mobileChangeSuccess;
    this.scrollingService.scrollToTop();
    this.isCustomerFromSDW = this.authService.isNovaSDW();
  }

  navigateToServiceHub() {
    this.serviceHubRedirectService.doRedirect();
  }
}
