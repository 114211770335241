import {from as observableFrom, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import imageCompression from 'browser-image-compression';

@Injectable({
  providedIn: 'root'
})
export class HandleUploadedFileService {

  public static hasMSGFileExtension(file: File): boolean {
    return !!file.name && file.name.endsWith('.msg');
  }

  constructor() {
  }

  getBase64Image(file: File): Observable<string> {

    return new Observable((observer) => {
      const reader = new FileReader();
      reader.onload = () => {
        observer.next(reader.result as string);
        observer.complete();
      };
      reader.onerror = () => {
        observer.error();
      };
      reader.readAsDataURL(file);
    });
  }

  parseBase64Image(dataUrl: string): string {
    const match = dataUrl.match(/^data:(.+?);base64,(.+)$/);
    if (!match) {
      throw new Error('invalid data url');
    }
    return match[2];
  }

  isAcceptedFileFormat(file: File, acceptedFileFormat: string): boolean {
    if (file.type) {
      let hasAcceptedFileFormat = true;
      if (acceptedFileFormat) {
        const formatMatcher = acceptedFileFormat.toLowerCase().replace(/,/g, '|');
        hasAcceptedFileFormat = !!file.type.match(formatMatcher.replace(/\s/g, ''));
      }

      return hasAcceptedFileFormat;
    }

    return false;
  }

  isAcceptedCompressionType(file: File): boolean {
    if (file.type) {
      return !!file.type.match(/(jpeg|jpg|png)/);
    }
    return false;
  }

  transformByteToMB(size: number): number {
    return Math.round(size / 1000000 * 100) / 100;
  }

  observableFromCompression(proccessedFile: File): Observable<any> {
    const fileSizeMB = this.transformByteToMB(proccessedFile.size);
    const options = {
      maxSizeMB: this.compressionMaxSize(fileSizeMB),
      maxWidthOrHeight: 1920,
      useWebWorker: false
    };
    return observableFrom(imageCompression(proccessedFile, options));
  }

  compressionMaxSize(size: number): number {
    if (size <= 1) {
      return 0.9 * size;
    } else if (size > 1 && size <= 2) {
      return 0.8 * size;
    } else if (size > 2 && size <= 3) {
      return 0.7 * size;
    } else {
      return 3;
    }
  }

  sanitizeMsgFile(file: File): File {
    if (!file.type && HandleUploadedFileService.hasMSGFileExtension(file)) {
      // IE 11 does not suppot File constructor
      // https://wiliammbr.com/file-constructor-not-supported-in-internet-explorer/
      const applicationOctetStreamType = 'application/octet-stream';
      try {
        return new File([file], file.name, {type: applicationOctetStreamType, lastModified: file.lastModified});
      } catch (e) {
        const blob = new Blob([file.slice()], {type: applicationOctetStreamType});
        const nameKey = 'name';
        const lastModifiedDateKey = 'lastModifiedDate';
        blob[nameKey] = file.name;
        blob[lastModifiedDateKey] = file[lastModifiedDateKey];
        // if in TypeScript, ensure that you are passing the cast
        return Object.assign(blob, {lastModified: file.lastModified, type: applicationOctetStreamType}) as File;
      }
    }
    return file;
  }
}
