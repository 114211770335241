import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KommDataEditComponent} from './components/komm-data-edit/komm-data-edit.component';
import {SharedModule} from '../shared/shared.module';
import {FeedbackModule} from '../shared/feedback/feedback.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StylesModule} from '../styles/styles.module';
import {NxAccordionModule} from '@aposin/ng-aquila/accordion';
import {NxInputModule} from '@aposin/ng-aquila/input';
import {NxFormfieldModule} from '@aposin/ng-aquila/formfield';
import {NxIconModule} from '@aposin/ng-aquila/icon';
import {NxLinkModule} from '@aposin/ng-aquila/link';
import {NxGridModule} from '@aposin/ng-aquila/grid';
import {NxButtonModule} from '@aposin/ng-aquila/button';
import {NxMessageModule} from '@aposin/ng-aquila/message';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import {NxtContactDataInputModule} from '@nxt/ngx-ndbx-extensions/contact-data-input';
import {TranslateModule} from '@ngx-translate/core';
import {NxtSummaryModule} from '@nxt/ngx-ndbx-extensions/summary';
import {NxCardModule} from '@aposin/ng-aquila/card';
import {NxCheckboxModule} from '@aposin/ng-aquila/checkbox';
import {NxListModule} from '@aposin/ng-aquila/list';
import {KommDataEditRoutingModule} from './komm-data-edit-routing.module';
import {KommDataAddModule} from '../komm-data-add/komm-data-add.module';
import {NxTableModule} from '@aposin/ng-aquila/table';
import {NxCircleToggleModule} from '@aposin/ng-aquila/circle-toggle';
import {NxTooltipModule} from '@aposin/ng-aquila/tooltip';
import {NxBadgeModule} from '@aposin/ng-aquila/badge';
import {PhoneNumberFormatPipe} from '../core/pipes/mobile-number-format.pipe';

@NgModule({
  declarations: [KommDataEditComponent],
  imports: [
    CommonModule,
    SharedModule,
    StylesModule,
    KommDataEditRoutingModule,
    CommonModule,
    FeedbackModule,
    FormsModule,
    StylesModule,
    NxAccordionModule,
    NxInputModule,
    NxFormfieldModule,
    NxIconModule,
    NxLinkModule,
    NxGridModule,
    NxButtonModule,
    NxMessageModule,
    NxHeadlineModule,
    NxtContactDataInputModule,
    NxInputModule,
    NxFormfieldModule,
    NxFormfieldModule,
    NxIconModule,
    NxIconModule,
    NxLinkModule,
    NxLinkModule,
    NxGridModule,
    NxGridModule,
    NxButtonModule,
    NxButtonModule,
    NxMessageModule,
    NxMessageModule,
    NxHeadlineModule,
    NxHeadlineModule,
    NxListModule,
    NxCheckboxModule,
    NxCardModule,
    NxtContactDataInputModule,
    NxtContactDataInputModule,
    ReactiveFormsModule,
    FormsModule,
    FeedbackModule,
    NxtSummaryModule,
    TranslateModule,
    KommDataAddModule,
    NxTableModule,
    NxCircleToggleModule,
    NxTooltipModule,
    NxBadgeModule
  ]
})
export class KommDataEditModule {
}
