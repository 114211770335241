import {Injectable} from '@angular/core';
import {WindowRefService} from './window-ref.service';

@Injectable()
export class ScrollingService {

  constructor(private readonly windowRefService: WindowRefService) {
  }

  scrollToTop(): void {
    this.windowRefService.nativeWindow.scrollTo(0, 0);
  }
}
