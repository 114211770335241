import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BackendResponse} from '../models/backend-data-model';
import {SabsPersonModel} from '../models/sabs-person.model';

@Injectable()
export class SabsPersonService {

  private readonly backendUrl = 'api/rest/dss/absperson/';

  constructor(private readonly http: HttpClient) {
  }

  public getAbsPerson(): Observable<BackendResponse<SabsPersonModel>> {
    return this.http.get<BackendResponse<SabsPersonModel>>(this.backendUrl);
  }
}
