import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DefaultLayoutComponent} from './shared/components/default-layout/default-layout.component';
import {UnauthorizedComponent} from './shared/components/unauthorized/unauthorized.component';
import {UnAuthenticatedGuard} from './core/guards/un-authenticated.guard';
import {ErrorComponent} from './shared/components/error/error.component';
import {KommDataResolver} from './core/resolver/komm-data.resolver';


const routes: Routes = [
  {
    path: '',
    canActivate: [UnAuthenticatedGuard],
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'wewe',
        loadChildren: () => import('./wewe/wewe.module').then(m => m.WeweModule)
      },
      {
        path: 'add',
        loadChildren: () => import('./komm-data-add/komm-data-add.module').then(m => m.KommDataAddModule),
        resolve: {
          kommDataResolver: KommDataResolver
        }
      },
      {
        path: 'change',
        loadChildren: () => import('./komm-data-change/komm-data-change.module').then(m => m.KommDataChangeModule)
      },
      {
        path: 'edit',
        loadChildren: () => import('./komm-data-edit/komm-data-edit.module').then(m => m.KommDataEditModule),
        resolve: {
          kommDataResolver: KommDataResolver
        }
      },
      {
        path: 'error',
        component: ErrorComponent
      },
      {path: '**', redirectTo: 'wewe'}
    ]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {path: '**', redirectTo: 'wewe'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
