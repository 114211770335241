<div nxLayout="grid maxwidth" class="success-component-container">

  <div nxRow nxRowJustify="center" class="nx-margin-top-xl">
    <dsmkd-circle-check-animation></dsmkd-circle-check-animation>
  </div>
  <div nxRow nxRowJustify="center" class="nx-margin-top-2m">
    <div nxCol="6" class="u-text-align-center">
      <h1 nxHeadline="section nx-margin-bottom-xs">{{'kommdata.success.headline.message' | translate}}</h1>
    </div>
  </div>

  <div class="nx-margin-top-2m">
    <div nxRow nxRowJustify="center" *ngIf="addedKommdataSuccessful()">
      <div nxCol="6" class="u-text-align-left komm-data-success-display">
        <nx-icon size="s" name="check" class="komm-data-change-success-icon"></nx-icon>
        <p nxCopytext="normal" class="nx-margin-bottom-s"
           [innerHTML]="'kommdata.success.headline.detailmessage.kommdata.add' | translate"></p>
      </div>
    </div>
    <div nxRow nxRowJustify="center" *ngIf="addedKommdataSuccessful()">
      <div nxCol="6" class="u-text-align-left komm-data-success-display">
        <nx-icon size="s" name="check" class="komm-data-change-success-icon"></nx-icon>
        <p nxCopytext="normal" class="nx-margin-bottom-s"
           [innerHTML]="'kommdata.success.headline.detailmessage.kommdata.abs.sync' | translate"></p>
      </div>
    </div>
    <div nxRow nxRowJustify="center" *ngIf="mazConsentSuccessful()">
      <div nxCol="6" class="u-text-align-left komm-data-success-display">
        <nx-icon size="s" name="check" class="komm-data-change-success-icon"></nx-icon>
        <p nxCopytext="normal" class="nx-margin-bottom-s"
           [innerHTML]="'kommdata.success.headline.detailmessage.program' | translate"></p>
      </div>
    </div>
  </div>

  <div *ngIf="showRegErrorMessage()" nxRow nxRowJustify="center" class="nx-margin-top-2m">
    <div nxCol="7" class="u-text-align-center">
      <nx-message nxContext="warning">
        {{'kommdata.dai.error.message' | translate}}
      </nx-message>
    </div>
  </div>

    <div *ngIf="cameFromEdit" nxRow nxRowJustify="center" class="nx-margin-top-2m">
        <div nxCol="10,10,12,12" class="u-text-align-center">
            <nx-link (click)="navigateToEdit()" nxStyle="block">
                {{'kommdata.success.back.to.edit' | translate}}
            </nx-link>
        </div>
    </div>

  <div *ngIf="!isCustomerFromSDW" nxRow nxRowJustify="center" class="nx-margin-top-2m">
    <div id="back-link" nxCol="10,10,12,12" class="u-text-align-center">
      <nx-link (click)="navigateToServiceHub()" nxStyle="block">
        {{'kommdata.success.back' | translate}}
      </nx-link>
    </div>
  </div>

  <dsmkd-voc-teaser></dsmkd-voc-teaser>

</div>
