import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {WeweRequest} from '../models/wewe.request';
import {BackendResponse} from '../models/backend-data-model';
import {LoadingAnimationService} from './loading-animation.service';
import {WeweResponse} from '../models/wewe.response';

@Injectable()
export class WeweService {

  constructor(private readonly httpclient: HttpClient, private readonly loadingAnimationService: LoadingAnimationService) {
  }

  sendWewe(data: WeweRequest): Observable<BackendResponse<WeweResponse>> {
    return this.httpclient.post<BackendResponse<WeweResponse>>('api/rest/dss/wewe', data);
  }

  canWeweProcessedForAbsPerson(): Observable<BackendResponse<void>> {
    // load in background -> now loading animation
    this.loadingAnimationService.skip();
    return this.httpclient.post<BackendResponse<void>>('api/rest/dss/wewe/canWeweProcessed', null);
  }
}
