import {Component, OnInit} from '@angular/core';
import {UrlApiService} from '../../../core/services/url-api.service';
import {ScrollingService} from '../../../core/services/scrolling.service';

@Component({
  selector: 'dsmkd-kommdata-success',
  templateUrl: './komm-data-success.component.html',
  styleUrls: ['./komm-data-success.component.scss']
})
export class KommDataSuccessComponent implements OnInit{

  constructor(private readonly urlApiService: UrlApiService,
              private readonly scrollingService: ScrollingService) {
  }

  ngOnInit(): void {
    this.scrollingService.scrollToTop();
  }

  showMazSuccess(): boolean {
    return this.urlApiService.getParams()?.inboundRoute === 'DSM_ATTESTATION';
  }


}
