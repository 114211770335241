<div nxLayout="grid maxwidth" class="nx-margin-top-4m">

  <div nxRow nxRowJustify="center">
    <div nxCol="12,12,6">
      <dsmkd-headers titleId="travel-select-contract-title"
                     title="Meine Allianz Kommunikationsdaten"
                     mainTitle="Fast geschafft! Bitte überprüfen Sie Ihre Angaben"
                     subtitleId="sub-title"></dsmkd-headers>
    </div>
  </div>

  <div nxRow nxRowJustify="center">
    <div nxCol="12,12,6" class="komm-data-summary">
      <nxt-summary class="komm-data-summary"
                   title="Geänderte mAZ Kommunikationsdaten"
                   [summaryData]="summaryData"
                   actionLabel="Bearbeiten"
                   actionIconName="pen"
                   (actionClick)="returnToChangeKommData()">
      </nxt-summary>
    </div>
  </div>

  <div nxRow nxRowJustify="center">
    <div nxCol="12,12,6">
      <nx-message *ngIf="showError" nxContext="error">
        Aus technischen Gründen konnten die Meine Allianz Kommunikationsdaten leider nicht geändert werden.
        Bitte versuchen Sie es später erneut. Sie können diese Seite geöffnet lassen und wieder auf „Bestätigen” klicken.
      </nx-message>
      <nx-message *ngIf="showEmailAlreadyExistsError" nxContext="error">
        Die angegebene E-Mail-Adresse wird bereits für das Meine Allianz Konto einer anderen Person verwendet.
        Bitte geben Sie eine andere E-Mail-Adresse an.
      </nx-message>
    </div>
  </div>

  <div class="nx-margin-top-4m" nxRow nxRowJustify="center">
    <div class="button-wrapper dssbs-padding-bottom-s" nxCol="12,12,3" nxAlignSelf="center,center,end"
         nxColOrder='first,first,last,last'>
      <button class="button-right-small-up nx-margin-bottom-0" nxButton="primary" type="submit"
              (click)="onSubmit()">
        Bestätigen
      </button>
    </div>

    <div class="button-wrapper link-text-center-small dssbs-padding-bottom-s" nxCol="12,12,3"
         nxAlignSelf="center">
      <button nxPlainButton type="button" (click)="returnToChangeKommData()" class="nx-margin-bottom-0">
        ZURÜCK
      </button>
    </div>
  </div>

  <div nxRow nxRowJustify="center" class="nx-padding-top-s">
    <div nxCol="12,12,6">
      <dsmkd-feedback-for-service></dsmkd-feedback-for-service>
    </div>
  </div>
</div>
