import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {FeedbackData} from '../model/feedback-data.model';
import {BackendResponse} from '../../../core/models/backend-data-model';
import {AppStateService} from '../../../core/services/app-state.service';
import {AuthInfoModel} from '../../../core/models/auth-info.model';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  private readonly showFeedbackButton: ReplaySubject<boolean>;

  private readonly backendFeedbackUrl = 'api/rest/dss/feedback';

  constructor(private readonly http: HttpClient,
              private readonly appStateService: AppStateService) {
    this.showFeedbackButton = new ReplaySubject<boolean>();
  }

  sendFeedback(feedbackData: FeedbackData): Observable<BackendResponse<null>> {
    const auth = this.appStateService.authInfo || {} as AuthInfoModel;
    const feedbackDataWithAuthInfos = Object.assign({}, feedbackData,
      {
        fk: auth.fk,
        aa: auth.aa,
        ga: auth.omModel.context,
        ng: auth.ng
      });
    return this.http.post<BackendResponse<null>>(this.backendFeedbackUrl, feedbackDataWithAuthInfos);
  }

}
