import {Component, OnInit} from '@angular/core';
import {KommDataPostResponse} from '../../../core/models/komm-data-post.response';
import {AppStateService} from '../../../core/services/app-state.service';
import {AttestationRedirectService} from '../../../core/services/attestation-redirect.service';

@Component({
  selector: 'dsmkd-kommdata-maz-esb-success',
  templateUrl: './komm-data-maz-esb-success.component.html',
  styleUrls: ['./komm-data-maz-esb-success.component.scss']
})
export class KommDataMazEsbSuccessComponent implements OnInit {

  private kommDataAddResponse: KommDataPostResponse;

  constructor(private readonly appStateService: AppStateService,
              private readonly attestationRedirectService: AttestationRedirectService) {
  }

  ngOnInit(): void {
    this.kommDataAddResponse = this.appStateService.kommDataPostResponse;
  }


  showDaiErrorMessage(): boolean {
    // if registrationResponse is undefined or daiCallSuccessful is null we don't show the error message
    return this.kommDataAddResponse.registrationResponse?.registrationCallSuccessful === false;
  }

  navigateToDsmbs() {
    this.attestationRedirectService.doRedirect();
  }

}
