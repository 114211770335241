import {Injectable} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {RedirectService} from './redirect.service';
import {ConfigurationService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AttestationRedirectService {

  constructor(private readonly authService: AuthService,
              private readonly redirectService: RedirectService,
              private readonly configService: ConfigurationService) {
  }

  doRedirect(): void {
    this.configService.getConfig().subscribe(config => {
      this.redirectService.redirect(`${config.serviceHubUrl}/bescheinigung/agent?inboundRoute=DSM_COMMUNICATION_DATA`);
    });
  }
}
