import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {KommDataEditComponent} from './components/komm-data-edit/komm-data-edit.component';


const routes: Routes = [
  {path: '', component: KommDataEditComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  declarations: [],
})
export class KommDataEditRoutingModule {
}
