import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  private readonly LOCAL_SERVICE_HUB_URL = new RegExp('^http?://(localhost:18000)', 'i');
  private readonly DEV_SERVICE_HUB_URL = new RegExp('^https?://(dss-dev.allianz.de)', 'i');
  private readonly UAT_SERVICE_HUB_URL = new RegExp('^https?://(dss-uat.allianz.de)', 'i');

  getUrl(): string {
    return window.location.href;
  }

  isLocalOrTeststageURL(): boolean {
    const windowUrl = this.getUrl();
    let isLocalOrTeststage = false;
    if (this.LOCAL_SERVICE_HUB_URL.test(windowUrl) || this.DEV_SERVICE_HUB_URL.test(windowUrl) || this.UAT_SERVICE_HUB_URL.test(windowUrl)) {
      isLocalOrTeststage = true;
    }
    return isLocalOrTeststage;
  }
}
