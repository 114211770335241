import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from '../services/auth/auth.service';

const UNAUTHORIZED_URL = 'unauthorized';

@Injectable({
  providedIn: 'root'
})
export class UnAuthenticatedGuard implements CanActivate {

  constructor(private readonly authService: AuthService,
              private readonly router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const isAuthedAsAgent = this.authService.isAgent();
    if (!isAuthedAsAgent) {
      return this.router.parseUrl(UNAUTHORIZED_URL);
    }
    return isAuthedAsAgent;
  }
}
