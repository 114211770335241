import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {KommDataAddSuccessComponent} from '../../komm-data-add/components/komm-data-add-success/komm-data-add-success.component';

@Injectable({
  providedIn: 'root'
})
export class SuccessPageDeactivationGuard implements CanDeactivate<KommDataAddSuccessComponent> {

  canDeactivate(component: KommDataAddSuccessComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree { // NOSONAR

    return nextState?.url === '/edit';

  }

}
