import {Component, Input} from '@angular/core';
import {AmslPersonModel} from '../../../core/models/amsl-person.model';

@Component({
  selector: 'dsmkd-wewe-person-detail',
  templateUrl: './wewe-person-detail.component.html',
  styleUrls: ['./wewe-person-detail.component.scss']
})
export class WewePersonDetailComponent {

  @Input() person: AmslPersonModel;

  getAnrede() {
    if (this.person.personType === 'JURPERS') {
      return 'Firma';
    } else if (this.person.gender === 'FEMALE') {
      return 'Frau';
    } else if (this.person.gender === 'MALE') {
      return 'Herr';
    }
    return '';
  }

  get initials() {
    if (!this.person.firstname || !this.person.lastname) {
      return null;
    }

    const f = this.person.firstname.charAt(0);
    const l = this.person.lastname.charAt(0);

    return f + l;
  }

}

