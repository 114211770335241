import {Component, Input} from '@angular/core';

@Component({
  selector: 'dsmkd-circle-check-animation',
  templateUrl: './circle-check-animation.component.html',
  styleUrls: ['./circle-check-animation.component.scss']
})

export class CircleCheckAnimationComponent {

  @Input() size: string;

  isIE11() {
    return window.navigator.userAgent.indexOf('Trident/') > 0;
  }
}
