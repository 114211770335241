<dsmkd-header></dsmkd-header>
<div nxLayout="grid maxwidth" class="margin-top-48">
  <div nxRow nxRowJustify="center">
    <div nxCol="12,12,8,8">
      <div class="card-header u-text-align-center">
        <h2 nxHeadline="section" class="u-text-align-center">Sie sind nicht eingeloggt</h2>
        <p nxCopytext="large" class="u-text-align-center u-margin-bottom-lg">
          Bitte starten Sie die Anwendung erneut.
        </p>
      </div>
    </div>
  </div>
</div>
<dsmkd-footer></dsmkd-footer>
