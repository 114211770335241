import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  private readonly AUTH_SERVICE_HUB_KEY = 'serviceQueryParams';
  private readonly LEGACY_AUTH_SERVICE_HUB_KEY = 'dsm-servicehub:auth';
  private readonly PROCESS_TRANSPARENCY_KEY = 'processTransparencyApi';

  constructor(private readonly authService: AuthService) {
  }

  getAbsCustomerId(): string {
    let customerId = this.getValueFromAuthServiceHubFor(this.AUTH_SERVICE_HUB_KEY, 'absCustomerId');
    if (customerId === '') {
      customerId = this.getValueFromAuthServiceHubFor(this.LEGACY_AUTH_SERVICE_HUB_KEY, 'absCustId');
    }
    return customerId;
  }

  getAmisPersonId(): string {
    let amisPersonId = this.getValueFromAuthServiceHubFor(this.AUTH_SERVICE_HUB_KEY, 'amisPersonId');
    if (amisPersonId === '') {
      amisPersonId = this.getValueFromAuthServiceHubFor(this.LEGACY_AUTH_SERVICE_HUB_KEY, 'amisPersonId');
    }
    return amisPersonId;
  }

  getAgentNumber(): string {
    let agentNumber = this.getValueFromAuthServiceHubFor(this.AUTH_SERVICE_HUB_KEY, 'agentNumber');
    if (agentNumber === '') {
      agentNumber = this.getValueFromAuthServiceHubFor(this.PROCESS_TRANSPARENCY_KEY, 'vtnr');
    }
    return agentNumber.replace(/\//g, '');
  }

  getToken(): string {
    let clientToken = this.getValueFromAuthServiceHubFor(this.AUTH_SERVICE_HUB_KEY, 'clientToken');
    if (clientToken === '') {
      clientToken = this.getValueFromAuthServiceHubFor(this.LEGACY_AUTH_SERVICE_HUB_KEY, 'token');
    }
    return clientToken;
  }

  hasToken(): boolean {
    return this.getToken() !== '';
  }

  private getValueFromAuthServiceHubFor(itemKey: string, key: string): string {
    if (this.authService.isAgent()) {
      const authServiceHub = JSON.parse(sessionStorage.getItem(itemKey));
      if (authServiceHub && authServiceHub[key]) {
        return authServiceHub[key];
      }
    }
    return '';
  }
}
