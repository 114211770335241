import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {IFile} from '../../models/file.model';
import {LoadingAnimationService} from '../../../core/services/loading-animation.service';
import {TranslateService} from '@ngx-translate/core';
import {timeout} from 'rxjs/operators';
import {FileUploadConfigModel} from '../../models/file-upload-config.model';
import {HandleUploadedFileService} from '../../../core/services/handle-uploaded-file.service';
import {FilesUploadService} from '../../services/files-upload.service';
import {Animations} from '../../../shared/animations/sliq-slide-down';

const CONTENT_TYPE_FILE_FORMAT_ERROR = 'upload.document.file.format.error.text';
const SECURE_UPLOAD_ERRORS = {
  CONTENT_TYPE_NOT_REGISTERED: CONTENT_TYPE_FILE_FORMAT_ERROR,
  CONTENT_TYPE_UNKNOWN: CONTENT_TYPE_FILE_FORMAT_ERROR,
  FILE_TOO_BIG: 'upload.document.file.size.error.text'
};

const DEFAULT_DROP_AREA_CSS = 'rectangle-upload-box-file-drop';
const ERROR_DROP_AREA_CSS = 'rectangle-upload-box-file-drop upload-box-file-drop-error';

@Component({
  selector: 'dsmkd-files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss'],
  animations: [
    Animations.sliqTriggerScale,
    Animations.sliqTrigger
  ]
})
export class FilesUploadComponent implements OnInit {

  @Input() acceptedFileSize = 30;
  @Input() acceptedFileFormat: string;
  @Input() acceptedMaxFiles = 15;
  @Input() showInfoMsg: boolean;
  @Input() errorsPrefix = 'wewe.form.attachment.error';

  @Input() filesFormControl: FormControl;

  @Input() fileUploadConfig: FileUploadConfigModel;

  errorText: string;

  uploadedFiles: IFile[] = [];

  constructor(public readonly handleUploadedFileService: HandleUploadedFileService,
              public readonly loadingAnimationService: LoadingAnimationService,
              private readonly fileUploadService: FilesUploadService,
              private readonly translateService: TranslateService
  ) {

  }


  ngOnInit(): void {

    if (!this.fileUploadConfig) {
      throw new Error('No file upload configuration (attribute:InputfileUploadConfig") found for "' +
        '". Please choose a upload service defined in file-upload-template.ts before using FilesUploadComponent.');
    }
  }

  droppedFile(event): void {
    this.errorText = null;
    const files = (event as Array<any>);
    if (files && files.every) {
      const isEveryEventAFile = files.every(file => file.fileEntry && file.fileEntry.isFile);

      if (this.uploadedFiles.length + files.length <= this.acceptedMaxFiles && isEveryEventAFile) {
        this.checkAndSaveFile(files);
      }
    }
  }

  checkAndSaveFile(files: any[]): void {
    files.forEach(file => {
      this.loadingAnimationService.show();
      file.fileEntry.file(proccessedFile => {
        this.checkFileFormatAndSend(proccessedFile);
      });
      this.loadingAnimationService.hide();
    });
  }

  checkFileFormatAndSend(proccessedFile) {
    proccessedFile = this.handleUploadedFileService.sanitizeMsgFile(proccessedFile);

    if (proccessedFile.size && this.handleUploadedFileService.isAcceptedFileFormat(proccessedFile, this.acceptedFileFormat)) {
      const compressThisFile = this.handleUploadedFileService.isAcceptedCompressionType(proccessedFile);
      if (compressThisFile) {
        this.compressAndSendFile(proccessedFile);
      } else {
        this.checkFileSizeAndSend(proccessedFile);
      }
    } else {
      // handle if type of file is not accepted
      const formats = this.acceptedFileFormat ? this.acceptedFileFormat.toUpperCase() : 'all formats';
      const errorMsg = !proccessedFile.size ? 'upload.document.file.noSize.error.text' : CONTENT_TYPE_FILE_FORMAT_ERROR;
      this.translateService.get(errorMsg, {formats}).subscribe((s: string) => {
        this.errorText = s;
      });
    }
  }

  compressAndSendFile(proccessedFile) {
    this.handleUploadedFileService.observableFromCompression(proccessedFile)
      .pipe(timeout(10000))
      .subscribe((compressedImage: File) => {
        this.checkFileSizeAndSend(compressedImage);
      }, (err) => {
        this.checkFileSizeAndSend(proccessedFile);
      });
  }

  deleteFile(file: IFile) {
    const index: number = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
      this.updateFormControl();
    }
  }

  hasFiles(): boolean {
    return this.uploadedFiles && this.uploadedFiles.length > 0;
  }

  maxFilesReached(): boolean {
    return this.uploadedFiles && this.uploadedFiles.length >= this.acceptedMaxFiles;
  }

  maxFilesReachedErrorText(): string {
    return this.translateService.instant('upload.document.max.files.reached.text', {maxFiles: this.acceptedMaxFiles});
  }

  supportedFormatsText(): string {
    return this.translateService.instant('upload.document.supported.formats.text', {formats: this.acceptedFileFormat.toUpperCase()});
  }

  supportedSizeText(): string {
    return this.translateService.instant('upload.document.supported.size.text', {maxSize: this.acceptedFileSize});
  }

  supportedMaxFilesText(): string {
    return this.translateService.instant('upload.document.supported.maxFiles.text', {maxFiles: this.acceptedMaxFiles});
  }

  downloadFile(file: IFile) {
    if (file === undefined || file.image === undefined) {
      return;
    }

    // see https://stackblitz.com/edit/angular-file-save-as?file=src%2Fapp%2Ffile-save-as%2Ffile-save-as.component.ts
    // https://developer.mozilla.org/de/docs/Web/API/URL/createObjectURL
    // https://developer.mozilla.org/de/docs/Web/API/URL/revokeObjectURL
    // and https://caniuse.com/#feat=bloburls
    const blob = file.image;

    // from https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/msSaveOrOpenBlob
      window.navigator.msSaveOrOpenBlob(blob, file.name);
      return;
    }
    // create a temporary link and fill it with data:
    const link = window.document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.rel = 'noopener';
    link.download = file.name;
    setTimeout(() => {
      link.click();
    }, 10);
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
    }, 40000);
  }

  checkFileSizeAndSend(fileToSend: File) {
    // USE FOR TESTING HOW THE COMPRESSES IMAGES LOOK?
    /*
    this.handleUploadedFileService.getBase64Image(fileToSend).subscribe(value => {
      this.theImage = value;
    });
    */

    const fileSizeInMB = this.handleUploadedFileService.transformByteToMB(fileToSend.size);
    if (fileSizeInMB <= this.acceptedFileSize) {
      this.sendFile(fileToSend);
    } else {
      this.loadingAnimationService.hide();
      this.translateService.get('upload.document.file.size.error.text', {maxSize: this.acceptedFileSize}).subscribe((s: string) => {
        this.errorText = s;
      });
    }
  }

  private sendFile(file: File) {
    this.fileUploadService.sendUploadedFile(file, this.fileUploadConfig).subscribe(res => {
      if (!res.errorCode) {
        const uploadedFile: IFile = {
          image: file,
          name: file.name,
          fileId: res.result
        };
        this.addUploadedFiles([uploadedFile]);
      } else {
        const errorTextCode = SECURE_UPLOAD_ERRORS[res.errorCode];
        this.translateService.get(errorTextCode).subscribe((s: string) => {
          this.errorText = s;
        });
      }
      this.loadingAnimationService.hide();
    }, errorRes => {
      let errorCode = 'upload.document.upload.error.text';
      if (errorRes.error && errorRes.error.errorCode && SECURE_UPLOAD_ERRORS[errorRes.error.errorCode]) {
        errorCode = SECURE_UPLOAD_ERRORS[errorRes.error.errorCode];
      }
      this.translateService.get(errorCode).subscribe((s: string) => {
        this.errorText = s;
      });
      this.loadingAnimationService.hide();
    });
  }

  private addUploadedFiles(files: IFile[]): void {
    files.forEach(value => this.uploadedFiles.push(value));
    this.updateFormControl();
  }

  private updateFormControl(): void {
    this.filesFormControl.markAsTouched();
    this.filesFormControl.setErrors(null);
    const newFileIds = Array.from(new Set(this.uploadedFiles.map(file => file.fileId)).values());
    this.filesFormControl.setValue(newFileIds);
  }

  cssClassForFileDropArea() {
    return this.filesFormControl.touched && this.filesFormControl.errors ? ERROR_DROP_AREA_CSS : DEFAULT_DROP_AREA_CSS;
  }

}

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void
  }
}
