import {NgModule} from '@angular/core';

import {NxtContactDataInputModule} from '@nxt/ngx-ndbx-extensions/contact-data-input';
import {FormsModule} from '@angular/forms';
import {NxModalModule, NxModalService} from '@aposin/ng-aquila/modal';
import {DatepickerIntlService} from '../core/services/datepicker-intl.service';
import {NxCheckboxModule} from '@aposin/ng-aquila/checkbox';
import {NxListModule} from '@aposin/ng-aquila/list';
import {NX_DATE_LOCALE, NxDatefieldModule, NxDatepickerIntl} from '@aposin/ng-aquila/datefield';
import {NxMomentDateModule} from '@aposin/ng-aquila/moment-date-adapter';
import {NxSpinnerModule} from '@aposin/ng-aquila/spinner';
import {NxLinkModule} from '@aposin/ng-aquila/link';
import {NxButtonModule} from '@aposin/ng-aquila/button';
import {NgxFileDropModule} from 'ngx-file-drop';
import {NxErrorModule} from '@aposin/ng-aquila/base';
import {NxCopytextModule} from '@aposin/ng-aquila/copytext';
import {NxInputModule} from '@aposin/ng-aquila/input';
import {NxGridModule} from '@aposin/ng-aquila/grid';
import {NxFormfieldModule} from '@aposin/ng-aquila/formfield';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import {NxMessageModule} from '@aposin/ng-aquila/message';
import {NxExpertModule} from '@aposin/ng-aquila/config';
import {NxIconModule} from '@aposin/ng-aquila/icon';
import {NdbxIconModule} from '@allianz/ngx-ndbx/icon';
import {NxtSummaryModule} from '@nxt/ngx-ndbx-extensions/summary';


const modules = [
  NxDatefieldModule,
  NxLinkModule,
  NxCheckboxModule,
  NxFormfieldModule,
  NxCopytextModule,
  NxGridModule,
  NxInputModule,
  NgxFileDropModule,
  NxErrorModule,
  NxIconModule,
  NxMessageModule,
  NxMomentDateModule,
  NxButtonModule,
  NxSpinnerModule,
  NxModalModule,
  NxHeadlineModule,
  NxListModule,
  NxtContactDataInputModule,
  FormsModule,
  NxExpertModule,
  NdbxIconModule,
  NxtSummaryModule
];

@NgModule({
  imports: modules,
  exports: modules,
  providers: [
    {
      provide: NxDatepickerIntl,
      useClass: DatepickerIntlService,
    },
    {
      provide: NX_DATE_LOCALE,
      useValue: 'de-DE'
    },
    NxModalService
  ]
})
export class StylesModule {
}
