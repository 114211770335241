import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FeedbackService} from '../../services/feedback.service';
import {NxDialogService} from '@aposin/ng-aquila/modal';
import {Router} from '@angular/router';
import {FeedbackDoneComponent} from '../feedback-done/feedback-done.component';

@Component({
  selector: 'dsmkd-feedback-for-service',
  templateUrl: './feedback-for-service.component.html',
  styleUrls: ['./feedback-for-service.component.scss']
})
export class FeedbackForServiceComponent implements OnInit {

  readonly controls = {
    message: new FormControl('', Validators.required),
    contactInformation: new FormControl(false),
    email: new FormControl('', Validators.email),
    phone: new FormControl(),
  };

  form: FormGroup;
  error400 = false;

  @ViewChild('feedbackModalBody') feedbackModalTemplate: TemplateRef<any>;

  constructor(private readonly feedbackService: FeedbackService,
              private readonly dialogService: NxDialogService,
              private readonly router: Router) {
  }

  public openFeedbackModal() {
    this.dialogService.open(this.feedbackModalTemplate, {
      ariaLabel: 'Feedback',
      closeIconButtonLabel: 'Dialog schließen',
      showCloseIcon: true,
      height: '620px',
      width: '736px'
    });
  }

  public closeFeedback() {
    this.dialogService.closeAll();
  }

  ngOnInit(): void {
    this.controls.contactInformation.valueChanges.subscribe(v => {
      if (v) {
        this.controls.email.enable({onlySelf: true});
        this.controls.phone.enable({onlySelf: true});
      } else {
        this.controls.email.disable({onlySelf: true});
        this.controls.phone.disable({onlySelf: true});
      }
    });
    this.controls.contactInformation.setValue(false);
    this.form = new FormGroup(this.controls);
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.feedbackService.sendFeedback({
        email: this.controls.email.value,
        phone: this.controls.phone.value,
        message: this.controls.message.value,
        page: this.router.url,
      }).subscribe(result => {
        this.dialogService.closeAll();
        this.dialogService.open(FeedbackDoneComponent, {
          ariaLabel: 'Feedback versendet',
          closeIconButtonLabel: 'Dialog schließen',
          showCloseIcon: true,
          height: '290px'
        });
      }, error => {
        if (error.status === 400) {
          this.error400 = true;
        }
      });
    }
  }

}
