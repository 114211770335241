import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'contactDataType'
})
export class ContactDataTypePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): number {
    switch (value) {
    case 'PRIVATE':
      return 0;
    case 'BUSINESS':
      return 1;
    case 'PRIVATE/BUSINESS':
      return 2;
    default:
      return 3;
    }
  }
}
