import { Pipe, PipeTransform } from '@angular/core';
import {ConsentType} from '../models/consent-element';

@Pipe({
  name: 'longVersionProgramType'
})
export class LongVersionProgramTypePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value) {
    case ConsentType.WEWE:
      return 'Werbliche Einwilligungserklärung';
    case ConsentType.ESB:
      return 'E-Mail statt Brief';
    case ConsentType.AVP:
      return  'Allianz Vorteilsprogramm';
    case ConsentType.DEWE:
      return 'Dateneinwilligungserklärung';
    default:
      return '';
    }
  }

}
