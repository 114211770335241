<div nxLayout="grid maxwidth" xmlns="http://www.w3.org/1999/html">

  <div nxRow nxRowJustify="center" class="nx-margin-top-xl">
    <dsmkd-circle-check-animation></dsmkd-circle-check-animation>
  </div>
  <div nxRow nxRowJustify="center" class="nx-margin-top-2m">
    <div nxCol="12" class="u-text-align-center">
      <h1 nxHeadline="section nx-margin-bottom-xs">{{'wewe.success.headline.message' | translate}}</h1>
    </div>
  </div>

  <div class="nx-margin-top-m">

    <div *ngIf="weweResponse?.weweState?.requested" nxRow nxRowJustify="center">
      <div nxCol="1" nxAlignSelf="start">
        <nx-icon class="float-right" name="check" size="s"></nx-icon>
      </div>
      <div nxCol="6" class="padding-left-0">
        <p class="nx-margin-bottom-0" nxCopytext="normal">{{'wewe.success.wewe.message' | translate}}</p>
        <p class="nx-margin-bottom-0 hint" nxCopytext="medium">{{'wewe.success.wewe.hint.' + id | translate}}</p>
      </div>
    </div>

    <div *ngIf="weweResponse?.mazState?.requested" class="nx-margin-top-s">

      <div *ngIf="weweResponse.mazState.successful" nxRow nxRowJustify="center">
        <div nxCol="1" nxAlignSelf="start">
          <nx-icon class="float-right" name="check" size="s"></nx-icon>
        </div>
        <div nxCol="6" class="padding-left-0">
          <p class="nx-margin-bottom-0" nxCopytext="normal">{{'wewe.success.maz.message' | translate}}</p>
          <p class="nx-margin-bottom-0 hint" nxCopytext="medium">{{'wewe.success.maz.hint.' + id | translate}}</p>
        </div>
      </div>

      <div *ngIf="!weweResponse.mazState.successful" nxRow nxRowJustify="center">
        <div nxCol="1" nxAlignSelf="start">
          <nx-icon class="float-right" name="exclamation-triangle-o" size="s"></nx-icon>
        </div>
        <div nxCol="6" class="padding-left-0">
          <p class="nx-margin-bottom-0" nxCopytext="normal">{{'wewe.error.maz.message' | translate}}</p>
          <p class="nx-margin-bottom-0 hint" nxCopytext="medium">{{'wewe.error.maz.hint' | translate}}</p>
        </div>
      </div>

    </div>
  </div>

  <div nxRow nxRowJustify="center" class="nx-margin-top-2m">
    <div id="back-link" nxCol="10,10,12,12" class="u-text-align-center">
      <nx-link class="u-text-uppercase">
        <a (click)="navigateToServiceHub()" href="javascript:void(0)">
          <span aria-hidden="true" class="nx-link__icon c-icon c-icon--arrow-right"></span>
          {{'wewe.success.back' | translate}}
        </a>
      </nx-link>

    </div>
  </div>

  <dsmkd-voc-teaser></dsmkd-voc-teaser>

</div>
