import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {OverlayService} from './overlay.service';
import {Router} from '@angular/router';

@Injectable()
export class HttpErrorService {

  constructor(private readonly overlayService: OverlayService,
              private readonly router: Router) {
  }

  handleHttpError(err: HttpErrorResponse): void {
    if (err.status === 401 || err.status === 403) {
      this.router.navigate(['unauthorized']);
    } else {
      this.overlayService.showErrorOverlay(err);
    }
  }
}
