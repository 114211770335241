
export interface KommDataChangeResponseModel {
  emailChangeSuccess: boolean;
  mobileChangeSuccess: boolean;
  errorType: ErrorType;

}

export enum ErrorType {
  MAZ_ALREADY_EXISTS
}
