import {Injectable} from '@angular/core';
import {WindowRefService} from './window-ref.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(private readonly windowRef: WindowRefService) {
  }

  public redirect(url: string): void {
    const fe = this.windowRef.nativeWindow.frameElement;
    if (fe) {
      this.windowRef.nativeWindow.top.location.href = url;
    } else {
      this.windowRef.nativeWindow.location.href = url;
    }
  }


}
