<ng-container *ngIf="person; else notFound">
  <div class="container">
    <div class="avatar-circle" *ngIf="initials">
      <span class="initials">{{ initials }}</span>
    </div>
    <p nxCopytext="normal" class="margin-bottom-16">
      <strong>
        {{getAnrede()}} {{person.firstname}} {{person.lastname}}
      </strong><br>
      <span nxCopytext="normal"
            *ngIf="person.mainAddress">{{person.mainAddress.street}} {{person.mainAddress.streetNumber}}
        , {{person.mainAddress.postcode}} {{person.mainAddress.city}}
        </span>
      <span nxCopytext="normal" *ngIf="person.birthday">, {{person.birthday | date: 'dd.MM.yyyy'}}</span>
    </p>
  </div>
</ng-container>

<ng-template #notFound>
  <span>Person ist Ihnen nicht zugeordnet</span>
</ng-template>
