import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AmslKommDataModel, AmslPersonModel} from '../models/amsl-person.model';
import {BackendResponse} from '../models/backend-data-model';

@Injectable()
export class AmslPersonService {

  private readonly backendUrl = 'api/rest/dss/person/';

  constructor(private readonly http: HttpClient) {
  }

  public getPerson(): Observable<BackendResponse<AmslPersonModel>> {
    return this.http.get<BackendResponse<AmslPersonModel>>(this.backendUrl);
  }

  public getKommData(): Observable<BackendResponse<AmslKommDataModel>> {
    return this.http.get<BackendResponse<AmslKommDataModel>>(`${this.backendUrl}kommdata`);
  }
}
