<div class="l-container l-container--full-width nx-margin-top-2xl">
  <div class="l-grid">
    <div class="l-grid__row u-margin-top-lg">
      <div class="l-grid__column u-text-align-center">
        <nx-icon class="t-primary-action-bright u-text-align-center" name="product-excavator" size='m'
                 outline="true"></nx-icon>
      </div>
    </div>

    <div class="l-grid__row" *ngIf="hasTranslatedTextForKey(mainMessage, mainMessageKey)">
      <div class="l-grid__column u-text-align-center">
        <h2 nxHeadline="section" class="u-margin-bottom-10">
          {{ mainMessage }}
        </h2>
      </div>
    </div>

    <div class="l-grid__row u-margin-top-sm">
      <div
        class="l-grid__column-small-10  offset-small-1 l-grid__column-medium-8  offset-medium-2 l-grid__column-large-6  offset-large-3 u-text-align-center">
        <p>
          {{'errorcomponent.' + id + '.detailMessage' | translate}}
        </p>
        <span *ngIf="hasTranslatedTextForKey(detailedMessage2ndLine, detailedMessage2ndLineKey)">
          {{ detailedMessage2ndLine }}
        </span>
      </div>

    </div>
  </div>

  <div class="l-grid__row margin-bottom-60" *ngIf="hasTranslatedTextForKey(helpLink, helpLinkKey)">
    <div class="l-grid__column-small-10 offset-small-1 u-text-align-center">
      <nx-link nxStyle="block">
        <a href="https://{{'errorcomponent.' + id + '.helpLink' | translate}}" target="_blank">
          {{ helpLink }}
        </a>
      </nx-link>
    </div>
  </div>

  <div class="l-grid__row u-margin-top-lg">
    <div class="l-grid__column-small-10 offset-small-1 u-text-align-center">
      <nx-link nxStyle="block">
        <a href="{{ 'errorcomponent.' + id +'.hrefLink'| translate}}">
          <nx-icon name='arrow-right'></nx-icon>
          {{ 'errorcomponent.' + id + '.back' | translate}}
        </a>
      </nx-link>
    </div>
  </div>
</div>
