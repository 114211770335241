import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'dsmkd-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent{

  @Input() titleId: string;
  @Input() title: string;
  @Input() mainTitleId: string;
  @Input() mainTitle: string;
  @Input() subtitleId: string;
  @Input() subtitle: string;
  @Input() showInfoIcon = false;

  constructor() { }


}
