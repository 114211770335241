import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TrackingAttribute} from '../models/tracking-attribute';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(private readonly httpclient: HttpClient) {
  }

  trackAttribute(key: string, value: string) {
    const trackingAttribute: TrackingAttribute = {
      key,
      value
    };
    return this.httpclient.post('kuendigung/api/vertreter/trackattribute', trackingAttribute).subscribe();
  }

  trackServiceHubStep(path: string, processStepName: string) {
    const trackingStep = {
      path,
      processStepName
    };
    return this.httpclient.post('namensaenderung/api/servicehub/trackstep', trackingStep).subscribe();
  }

  trackServiceHubAttribute(key: string, value: string) {
    const trackingAttribute: TrackingAttribute = {
      key,
      value
    };
    return this.httpclient.post('namensaenderung/api/servicehub/trackattribute', trackingAttribute).subscribe();
  }
}
