import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'dsmkd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dsmkd-frontend';

  constructor(private readonly translateService: TranslateService,
  ) {

    translateService.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translateService.use('de');
  }
}
