import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'dsmkd-voc-teaser',
  templateUrl: './voc-teaser.component.html',
  styleUrls: ['./voc-teaser.component.scss']
})
export class VocTeaserComponent implements OnInit {

  private readonly NEW_VOC_URL_START = 'https://allianz-deutschland.esaas.inmoment.eu/cgi-bin/qwebcorporate.dll?idx=AECSWC&OE=1&lg=German&';
  private readonly VOC_URL_END = '&DuSie1=1&DuSie2=0&DuSie3=0&DuSie4=0&DuSie5=0&DuSie6=0&DuSie7=0';

  private readonly VOC_FOR_WEWE = this.NEW_VOC_URL_START
    .concat('OE_12=20&subjourney=6&lineOfBusiness=3&branch=10&journey=4&OE_10=0&OE_11=7&Zielgruppe=5&interactionPointID=D-DSS-ELSE&OE_8=8&OE_9=8', this.VOC_URL_END);
  private readonly VOC_FOR_ADD = this.NEW_VOC_URL_START
    .concat('OE_12=70&subjourney=6&lineOfBusiness=3&branch=10&journey=4&OE_10=0&OE_11=11&Zielgruppe=5&interactionPointID=D-DSS-ELSE&OE_8=36&OE_9=12', this.VOC_URL_END);
  private readonly VOC_FOR_CHANGE = this.NEW_VOC_URL_START
    .concat('OE_12=70&subjourney=6&lineOfBusiness=3&branch=10&journey=4&OE_10=0&OE_11=11&Zielgruppe=5&interactionPointID=D-MAZ-ELSE&OE_8=36&OE_9=12', this.VOC_URL_END);

  vocUrl: string;

  constructor(private readonly router: Router) {
  }

  ngOnInit(): void {
    this.setVocUrlForApp();
  }

  private setVocUrlForApp() {
    if(this.router.url.includes('wewe')) {
      this.vocUrl = this.VOC_FOR_WEWE;
    } else if (this.router.url.includes('add')) {
      this.vocUrl = this.VOC_FOR_ADD;
    } else if (this.router.url.includes('change')) {
      this.vocUrl = this.VOC_FOR_CHANGE;
    }
  }
}
