import {animate, style, transition, trigger} from '@angular/animations';

export const Animations = {
  sliqTrigger: trigger('flyInOut', [
    transition('void => *', [
      style({height: '0px', opacity: 0}),
      animate('250ms ease')
    ]),
    transition('* => void', [
      animate('250ms ease', style({height: '0px', opacity: 0}))
    ])
  ]),

  sliqTriggerScale: trigger('scale', [
    transition('void => *', [
      style({transform: 'scale(0.8)', opacity: 0}),
      animate('150ms 30ms ease')
    ]),
    transition('* => void', [
      animate('25ms ease', style({transform: 'scale(0.8)', opacity: 0}))
    ])
  ]),
};
