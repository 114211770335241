import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {OverlayService} from '../../../core/services/overlay.service';
import {HttpErrorResponseUtils} from '../../../core/services/http-error-response-utils';

@Component({
  selector: 'dsmkd-error-overlay',
  templateUrl: './error-overlay.component.html',
  styleUrls: ['./error-overlay.component.scss']
})
export class ErrorOverlayComponent implements OnInit {

  showErrorOverlay = false;
  errorCodes: string;

  constructor(private readonly overlayService: OverlayService) {
  }

  ngOnInit() {
    this.overlayService.getHttpErrorSubject().subscribe(payload => this.evaluate(payload));
  }

  private evaluate(payload: HttpErrorResponse) {
    this.errorCodes = Array.from(HttpErrorResponseUtils.getErrorCodes(payload)).join(', ');
    this.showErrorOverlay = true;
  }
}
