import {Pipe, PipeTransform} from '@angular/core';
import {ConsentType} from '../models/consent-element';

@Pipe({
  name: 'displayedNameProgramType'
})
export class DisplayedNameProgramTypePipe implements PipeTransform {

  transform(programParticipation: string, ...args: unknown[]): string {
    switch (programParticipation) {
    case ConsentType.WEWE:
      return 'wEWE';
    case ConsentType.ESB:
      return 'EsB';
    case ConsentType.DEWE:
      return 'dEWE';
    default:
      return programParticipation;
    }
  }
}
