import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorMessage, Overlay} from '../../../core/models/resilience.model';
import {ServiceHubRedirectService} from '../../../core/services/service-hub-redirect.service';

@Component({
  selector: 'dsmkd-komm-data-resilience',
  templateUrl: './komm-data-resilience.component.html',
  styleUrls: ['./komm-data-resilience.component.scss']
})
export class KommDataResilienceComponent {
  title: string;
  subTitle: string;
  errorMessage: string;

  constructor(private readonly router: Router,
              private readonly serviceHubRedirectService: ServiceHubRedirectService) {

    const overlay = this.router.getCurrentNavigation().extras?.state.overlay;
    const errorMessage = this.router.getCurrentNavigation().extras?.state.errorMessage;

    this.assignTitleAndSubtitle(overlay);
    this.assignErrorMessage(errorMessage);
  }

  private assignTitleAndSubtitle(overlay): void {
    if (overlay === Overlay.DIGITAL_PROFILE) {
      this.title = 'Kommunikationsdaten';
    } else if (overlay === Overlay.PROGRAM_PARTICIPATION) {
      this.title = 'Programmteilnahmen';
      this.subTitle = 'Bitte wählen Sie, zu welchen weiteren Allianz-Programmen Sie den Kunden einladen möchten';
    }
  }

  private assignErrorMessage(errorMessage): void {
    if (errorMessage === ErrorMessage.OCS_RUNNING) {
      this.errorMessage = 'Für diesen Kunden läuft eine mAZ-Direktregistrierung. Der Kunde muss erst die ' +
        'Registrierung abschließen, im Anschluss können Sie ihn zu weiteren Programmen einladen.';
    } else if (errorMessage === ErrorMessage.LEGAL_ENTITY) {
      this.errorMessage = 'Für juristische Personen können an dieser Stelle derzeit noch keine weiteren ' +
        'Programme hinzugefügt werden.';
    }
  }


  navigateBack(): void {
    this.serviceHubRedirectService.doRedirect();
  }

}
