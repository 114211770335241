import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class  LoadingAnimationService {

  showLoading: Subject<boolean> = new ReplaySubject();

  private inflight = 0;
  private skipNext = 0;

  constructor() {
    this.showLoading.next(false);
  }

  show(): void {
    if (this.skipNext > 0) {

      this.skipNext--;
    } else {
      this.inflight++;
    }

    this.updateSubject();
  }

  hide(): void {
    this.inflight = Math.max(this.inflight - 1, 0);
    this.updateSubject();
  }

  skip(): void {
    this.skipNext++;
  }

  private updateSubject() {
    this.showLoading.next(this.inflight > 0);
  }

}
