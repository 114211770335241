import {Component, OnInit} from '@angular/core';
import {HeaderUserDetails} from '../../../core/models/header-user-details.model';
import {ModelService} from '../../../core/services/model.service';
import {TrackingService} from '../../../core/services/tracking.service';
import {Router} from '@angular/router';
import {SabsPersonService} from '../../../core/services/sabs-person.service';

@Component({
  selector: 'dsmkd-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  constructor(private readonly modelService: ModelService,
              private readonly trackingService: TrackingService,
              private readonly router: Router,
              private readonly sabsPersonService: SabsPersonService) {
  }

  ngOnInit(): void {
    this.sabsPersonService.getAbsPerson().subscribe(res => {
      this.modelService.setHeaderUserDetails({
        firstName: res.body.firstName,
        lastName: res.body.lastName,
        companyName: res.body.companyName,
        legalEntity: res.body.legalEntity
      } as HeaderUserDetails);
    },
    () => {
      this.router.navigate(['error', {id: 99999}]);
    });
  }

}
