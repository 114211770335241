import {Component} from '@angular/core';

@Component({
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {

  constructor() {
  }

}
