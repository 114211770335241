import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {KommDataDigitalProfileComponent} from './components/komm-data-digital-profile/komm-data-digital-profile.component';
import {SuccessPageDeactivationGuard} from '../core/services/success-page-deactivation.guard';
import {KommDataSuccessComponent} from './components/komm-data-success/komm-data-success.component';
import {KommDataProgramParticipationsComponent} from './components/komm-data-program-participations/komm-data-program-participations.component';
import {KommDataResilienceComponent} from './components/komm-data-resilience/komm-data-resilience.component';

const KOMM_DATA_ADD_PATH = 'digital-profile';
const PROGRAM_PARTICIPATIONS_PATH = 'program-participations';
const RESILIENCE_PATH = 'resilience';
const SUCCESS_PATH = 'success';

const routes: Routes = [

  {path: KOMM_DATA_ADD_PATH, component: KommDataDigitalProfileComponent},
  {path: PROGRAM_PARTICIPATIONS_PATH, component: KommDataProgramParticipationsComponent},
  {path: RESILIENCE_PATH, component: KommDataResilienceComponent},
  {path: SUCCESS_PATH, component: KommDataSuccessComponent, canDeactivate: [SuccessPageDeactivationGuard]},

  {path: '', redirectTo: KOMM_DATA_ADD_PATH, pathMatch: 'full'},
  {path: '**', redirectTo: KOMM_DATA_ADD_PATH},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KommDataAddRoutingModule {
}
