import {flatMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {FileUploadConfigModel} from '../models/file-upload-config.model';
import {HandleUploadedFileService} from '../../core/services/handle-uploaded-file.service';


@Injectable({
  providedIn: 'root'
})
export class FilesUploadService {

  private readonly urlUpload = 'store/upload';

  constructor(private readonly http: HttpClient,
              private readonly handleUploadedFileService: HandleUploadedFileService) {
  }

  sendUploadedFile(file: File, config: FileUploadConfigModel): Observable<any> {
    return this.handleUploadedFileService.getBase64Image(file).pipe(
      map(value => {
        let pdf = this.handleUploadedFileService.parseBase64Image(value);
        const decodedPdf = atob(pdf);
        pdf = btoa(this.removingDoubleQuotes(decodedPdf));

        return Object.assign({}, config, {
          pBlob: pdf,
          pFileName: file.name,
          pContentType: file.type,
          pUserTimestamp: new Date()
        });
      }),
      flatMap(value => {
        return this.http.post(this.urlUpload, value, {observe: 'response'});
      }
      ), map((response: HttpResponse<any>) => {
        return response.body;
      })
    );
  }

  public removingDoubleQuotes(decodedPdf: string) {
    if (decodedPdf.startsWith('\"\"%PDF')) {
      return decodedPdf.replace('\"\"%PDF', '%PDF');
    }
    return decodedPdf;
  }
}
