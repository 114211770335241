import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesUploadComponent } from './components/files-upload/files-upload.component';
import {TranslateModule} from '@ngx-translate/core';
import {StylesModule} from '../styles/styles.module';

@NgModule({
  declarations: [FilesUploadComponent],
  imports: [
    CommonModule,
    TranslateModule,
    StylesModule
  ],
  exports: [
    FilesUploadComponent
  ]
})
export class FilesUploadModule { }
