<nx-footer>
  <nx-footer-copyright>
    © Allianz {{calculateActualYear()}}
  </nx-footer-copyright>

  <nx-footer-navigation>
    <nx-footer-link>
      <a routerLink="https://www.allianz.de/impressum">Impressum</a>
    </nx-footer-link>
    <nx-footer-link>
      <a routerLink="https://www.allianz.de/datenschutz">Datenschutz</a>
    </nx-footer-link>
    <nx-footer-link>
      <a routerLink="https://www.allianz.de/nutzungsbedingungen">Nutzungsbedingungen</a>
    </nx-footer-link>
  </nx-footer-navigation>
</nx-footer>
