<form *ngIf="!maxFilesReached()">
  <div class="margin-bottom-20 box-wrapper">

    <ngx-file-drop id="dssn_formfield_file_drop"
                   [showBrowseBtn]="true"
                   [dropZoneClassName]="cssClassForFileDropArea()"
                   [multiple]="true"
                   (onFileDrop)="droppedFile($event)">

      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div>
          <div nxRow class="align-horizontal margin-bottom-12 nx-margin-0">
            <p nxCopytext="normal" class="nx-margin-0 t-rich-action-grey drag-document-text-title">
              {{'upload.document.drag.document' | translate}}
            </p>
          </div>
          <div nxRow class="align-horizontal">
            <p nxCopytext="normal" class="margin-bottom-8 t-rich-action-grey">
              oder
            </p>
          </div>
          <div nxRow class="align-horizontal">
            <nx-link id="click_upload_document" tabindex="0"
                     type="button"
                     (click)="openFileSelector()"
                     (keyup.enter)="openFileSelector()"
                     class="u-margin-top-none u-margin-bottom-none">
              {{'upload.document.upload.button' | translate}}
            </nx-link>
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>
  <nx-message *ngIf="errorText" nxContext="error" showIcon="true" [@scale]>
    <p nxCopytext="normal" class="nx-margin-0">
      <span>
        <strong>{{'global.error.title' | translate}}: </strong>
      </span>
      {{errorText}}
    </p>
  </nx-message>
  <nx-message *ngIf="showInfoMsg" class="accepted-file-info u-margin-top-none" nxContext="info" showIcon="true"
              [@scale]>
    <p nxCopytext="normal" class="nx-margin-0">
      <strong>{{'global.info.title' | translate}}: </strong>

      <span *ngIf="acceptedMaxFiles">{{supportedMaxFilesText()}}</span>
      <strong *ngIf="acceptedFileFormat">{{supportedFormatsText()}}</strong>
    </p>
  </nx-message>
</form>

<!-- UPLOADED FILE -->
<nx-message *ngIf="maxFilesReached()" nxContext="info" showIcon="true"
            [@scale]>
  <p nxCopytext="normal" class="nx-margin-0">
            <span>
              <strong>{{'global.info.title' | translate}}: </strong>
            </span>
    {{maxFilesReachedErrorText()}}
  </p>
</nx-message>
<div nxRow *ngIf="hasFiles()" [@flyInOut] id="upload_document_show_file_name" class="margin-top-24">
  <div nxCol="12" *ngFor="let file of uploadedFiles; let lastItem = last;">
    <hr>
    <div class="margin-top-12 margin-bottom-12">
      <nx-icon name="check" class="icon-check margin-right-8" size="s"></nx-icon>
      <nx-link (click)="downloadFile(file)" class="file-name-snippet padding-right-36">
        {{file.name}} ({{handleUploadedFileService.transformByteToMB(file.image.size)}} MB)
      </nx-link>
      <nx-link nxStyle='text' (click)="deleteFile(file)" class="float-right">
        <a>
          <nx-icon name="trash"></nx-icon>
        </a>
      </nx-link>
    </div>
    <hr *ngIf="lastItem">
  </div>
</div>

<nx-error *ngIf="filesFormControl?.touched && filesFormControl?.errors">
  <div *ngIf="filesFormControl.errors.required">
    {{ errorsPrefix + '.required' | translate }}
  </div>
</nx-error>

<!-- USE FOR TESTING HOW THE COMPRESSES IMAGES LOOK
  <img src="{{theImage}}">
-->
