<div nxLayout="grid maxwidth">

  <div nxRow nxRowJustify="center" class="nx-margin-top-xl">
    <dsmkd-circle-check-animation></dsmkd-circle-check-animation>
  </div>
  <div nxRow nxRowJustify="center" class="nx-margin-top-2m">
    <div nxCol="6" class="u-text-align-center">
      <h1 nxHeadline="section nx-margin-bottom-xs">{{'kommdata.success.headline.message.maz.esb' | translate}}</h1>
      <span [innerHTML]="'kommdata.success.headline.detailmessage.maz.esb' | translate">
      </span>
    </div>
  </div>

  <div *ngIf="showDaiErrorMessage()" nxRow nxRowJustify="center" class="nx-margin-top-2m">
    <div nxCol="6" class="u-text-align-center">
      <nx-message nxContext="warning">
        {{'kommdata.dai.error.message' | translate}}
      </nx-message>
    </div>
  </div>

  <div nxRow nxRowJustify="center" class="nx-margin-top-2m">
    <div nxCol="6" class="u-text-align-center">
      <button nxButton="primary" (click)="navigateToDsmbs()"
              type="button">{{'kommdata.success.back.maz.esb' | translate}}
      </button>
    </div>
  </div>

  <div nxRow nxRowJustify="center" class="nx-padding-top-s">
    <div nxCol="6" class="text-align-center">
      <dsmkd-feedback-for-service></dsmkd-feedback-for-service>
    </div>
  </div>

</div>
