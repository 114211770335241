import {Injectable} from '@angular/core';


export const SH_KEY = 'dsh-session-id';

@Injectable({
  providedIn: 'root'
})
export class SessionIdService {

  constructor() {
  }

  getDSHSessionId(): string {
    return sessionStorage.getItem(SH_KEY);
  }
}
