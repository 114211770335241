<form #form="ngForm" (submit)="onSubmit()" *ngIf="isDataPopulated">
  <div nxLayout="grid maxwidth" class="contact-data-input-remove-info nx-margin-top-4m">
    <div nxRow nxRowJustify="center">
      <div nxCol="12,12,6">
        <dsmkd-headers titleId="travel-select-contract-title"
                       title="Meine Allianz Kommunikationsdaten"
                       mainTitle="Kommunikationsdaten ändern"
                       subtitleId="sub-title"
                       subtitle="Bitte geben Sie an, welche Kommunikationsdaten zukünftig für Meine Allianz genutzt werden sollen."></dsmkd-headers>
      </div>
    </div>
    <div nxRow nxRowJustify="center">
      <div nxCol="12,12,6">
        <dsmkd-contact-data-input
          [fieldType]=emailFieldType
          #emailField
          [contactDataItems]="emails"
          [optoutDisabled]="true"
          [preselectedValue]="preselectedEmail"
        ></dsmkd-contact-data-input>

        <dsmkd-contact-data-input
          [fieldType]=mobileNumberFieldType
          #mobileNumberField
          [contactDataItems]="mobileNumbers"
          [optoutDisabled]="true"
          [preselectedValue]="preselectedMobileNumber"
        ></dsmkd-contact-data-input>
      </div>
    </div>

    <div nxRow nxRowJustify="center">
      <div nxCol="12,12,6">
        <h4 nxHeadline="subsection-xsmall"
            class="nx-font-weight-semibold nx-margin-bottom-m">
          Hinweise
        </h4>
        <ul nxList="small" class="nx-margin-bottom-2m">
          <li>Der Kunde erhält nach der Änderung eine Meldung an seine bisherigen und neuen Kommunikationsdaten (E-Mail
            und/oder SMS)
          </li>
          <li>Der Kunde muss die Änderung nicht bestätigen, sie ist sofort gültig
          </li>
        </ul>
      </div>
    </div>

    <div nxRow nxRowJustify="center">
      <div nxCol="12,12,6">
        <h4 nxHeadline="subsection-xsmall"
            class="nx-font-weight-semibold nx-margin-bottom-m nx-margin-top-m">
          Rechtliches
        </h4>
        <nx-checkbox class="nx-margin-bottom-s"
                     [(checked)]='checkedLegal'>Der Kunde wurde anhand eines Ausweisdokuments persönlich identifiziert.
          Die Änderung der Meine Allianz Kommunikationsdaten wird auf seinen ausdrücklichen Wunsch durchgeführt.
        </nx-checkbox>
      </div>
    </div>

    <div class="nx-margin-top-4m" nxRow nxRowJustify="center">
      <div class="button-wrapper dssbs-padding-bottom-s" nxCol="12,12,3" nxAlignSelf="center,center,end"
           nxColOrder='first,first,last,last'>
        <button class="button-right-small-up nx-margin-bottom-0" [disabled]=submitDisbled()
                nxButton="primary" type="submit">
          Weiter
        </button>
      </div>

      <div class="button-wrapper link-text-center-small dssbs-padding-bottom-s" nxCol="12,12,3"
           nxAlignSelf="center">
        <button nxPlainButton type="button" (click)="navigateToPreviousPage()" class="nx-margin-bottom-0">
          ZURÜCK
        </button>
      </div>
    </div>

    <div nxRow nxRowJustify="center" class="nx-padding-top-s">
      <div nxCol="12,12,6">
        <dsmkd-feedback-for-service></dsmkd-feedback-for-service>
      </div>
    </div>
  </div>
</form>
