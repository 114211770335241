<div nxLayout="grid maxwidth" class="contact-data-input-remove-not-available contact-data-input-remove-info">
  <div class="u-text-align-center nx-margin-top-xl" nxRow nxRowJustify="center">
    <div class="nx-margin-top-xl" nxCol="12,8,8,5">
      <div class="card-header">
        <span class="nx-font-weight-semibold u-text-align-center">Digitales Kundenprofil</span>
        <h2 nxHeadline="section" class="u-text-align-center margin-bottom-8">{{title}}</h2>
        <p nxCopytext="normal"
           class="u-text-align-center">{{subTitle}}</p>
      </div>

      <nx-message nxContext="warning">
        {{errorMessage}}
      </nx-message>
    </div>
  </div>

  <div class="nx-margin-top-4m" nxRow nxRowJustify="center">
    <div nxCol="12,12,5">
      <div nxRow>
        <div class="button-wrapper link-text-center-small dssbs-padding-bottom-s" nxCol="12" nxAlignSelf="left">
          <button nxPlainButton type="button" (click)="navigateBack()" class="nx-margin-bottom-0">
            ZURÜCK
          </button>
        </div>
      </div>

    </div>
  </div>

  <div nxRow nxRowJustify="center" class="nx-padding-top-s">
    <div nxCol="12,12,5">
      <dsmkd-feedback-for-service></dsmkd-feedback-for-service>
    </div>
  </div>

</div>
