import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WeweFormComponent} from './components/wewe-form/wewe-form.component';
import {WeweSuccessComponent} from './components/wewe-success/wewe-success.component';
import {ErrorComponent} from '../shared/components/error/error.component';

const routes: Routes = [
  {path: 'form', component: WeweFormComponent},

  {path: '', redirectTo: 'form', pathMatch: 'full'},
  {path: 'success', component: WeweSuccessComponent},
  {path: 'error', component: ErrorComponent},
  {path: '**', redirectTo: 'form'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WeweRoutingModule {
}
